import React from "react";
import { PATH } from "../../routes/Routes";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/common/antd/SubHeader";
import InputWoodData from "../../components/qc-post-baking-wood/InputWoodData";
import InputDetail from "../../components/qc-post-baking-wood/InputDetail";


const STEP = {
  INPUT_WOOD_DATA: 'input-wood-data',
  INPUT_DETAIL: 'input-detail',
}

export default function QCPostBakingWoodView () {
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = React.useState(STEP.INPUT_WOOD_DATA);
  const [data, setData] = React.useState({});

  const onInputWoodData = (values) => {
    setData({ ...data, ...values })
    setStep(STEP.INPUT_DETAIL)
  }

  const onBackToInputData = () => {
    setStep(STEP.INPUT_WOOD_DATA)
  }

  const renderStep = () => {
    switch (step) {
      case STEP.INPUT_WOOD_DATA:
        return <InputWoodData onSubmit={onInputWoodData}/>
      case STEP.INPUT_DETAIL:
        return <InputDetail data={data} onBack={onBackToInputData}/>
      default: return null
    }
  }

  const onBack = () => {
    history.push(PATH.QC_MAIN);
  }

  return (
    <Layout>
      <SubHeader title={t('qc_menus.post_baking_wood')} onBack={onBack}/>
      <Layout.Content>
        <div style={{ padding: '1rem 1rem' }}>
          {renderStep()}
        </div>
      </Layout.Content>
    </Layout>
  )
}