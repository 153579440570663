import { Button, Collapse, Flex, Form, Layout, message, Select, Spin, Table, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { SaveOutlined } from "@ant-design/icons";
import { URL_QC } from "../../constance/urls";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import SubHeader from "../../components/common/SubHeader";
import { GET, PATCH } from "../../utils/HttpClient";
import ErrorMessage from "../../components/common/antd/ErrorMessage";
import WetWoodDataDetail from "../../components/qc-wet-wood/WetWoodDataDetail";
import { defaultQCWetWoodTransactionDetail } from "../../constance/dataTypes";
import DetailForm from "../../components/qc-wet-wood/DetailForm";


export default function QCWetWoodReportDetailView () {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [data, setData] = React.useState(defaultQCWetWoodTransactionDetail);
  const [form] = Form.useForm();

  const onBack = () => {
    history.push(PATH.QC_WET_WOOD_REPORT)
  }

  const fetchData = async () => {
    try {
      const response = await GET(`${URL_QC.QC_WET_WOOD_TRANSACTION}${id}/`)
      setData(response.data);
      form.setFieldsValue(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);

    try {
      await PATCH(`${URL_QC.QC_WET_WOOD_TRANSACTION}${id}/`, values)
      message.success({ content: t('success') })
      fetchData();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <Layout>
      <Layout.Header>
        <SubHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.wet_wood')}</Typography.Title>
          <WetWoodDataDetail id={data.wet_wood_data}/>

          <Typography.Title level={2}>{t('qc_sort')}</Typography.Title>
          <ErrorMessage message={errorMessages}/>
          <Form form={form} onFinish={handleSubmit} labelCol={{ span: 4 }}>
            <Spin spinning={loading}>
              <DetailForm form={form}/>
            </Spin>
            <Flex justify='center' gap='middle'>
              <Button type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
              <Button onClick={onBack} loading={loading}>{t('back')}</Button>
            </Flex>
          </Form>
        </Container>
      </Layout.Content>
    </Layout>
  )
}