import React from "react";
import { GET } from "../../utils/HttpClient";
import { URL_QC } from "../../constance/urls";
import { Form, InputNumber, message, Radio } from "antd";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";


export default function DetailForm (props) {
  const {
    form,
  } = { ...defaultProps, ...props }

  const { t } = useTranslation(); 
  const qcPass = Form.useWatch('is_qc_passed', form)
  const details = Form.useWatch('details', form)
  const sumDefect = details ? details.reduce((prev, detail) => prev + Number(_.get(detail, 'percent', 0)) || Number(0), Number(0)) : 0
  const [settings, setSettings] = React.useState([]);

  const fetchDryWoodSetting = async () => {
    try {
      const response = await GET(URL_QC.QC_DRY_WOOD_SETTING, { page_size: 50 })
      setSettings(_.get(response, 'data.results', []))
    } catch (error) {
      message.error({ content: error.errorMessages })
    }
  }

  const setFormData = () => {
    const originalDetails = form.getFieldValue('details')
    const newDetails = settings.map(result => {
      const found = originalDetails ? originalDetails.find(e => e.setting == result.id) : null
      return {
        setting: result.id, setting_name: result.name,
        percent: _.get(found, 'percent', 0),
      }
    })
    form.setFieldValue('details', newDetails)
  }

  const resetDetails = () => {
    const originalDetails = form.getFieldValue('details')
    form.setFieldValue('details', originalDetails ? originalDetails.map(detail => ({ ...detail, percent: 0 })) : [])
  }

  React.useEffect(() => {
    fetchDryWoodSetting();
  }, [])

  React.useEffect(() => {
    if(settings.length > 0) {
      setFormData()
    }
  }, [settings, details])

  React.useEffect(() => {
    if (qcPass) {
      resetDetails();
    }
  }, [qcPass])

  return (
    <div>
      <Form.Item hidden name='dry_wood_data'/>

      <Form.Item name='is_qc_passed' rules={[{ required: true }]} label=''>
        <Radio.Group
          size='large'
          options={[
            { value: true, label: t('qc_sorts.pass'), },
            { value: false, label: t('qc_sorts.fail'), }
          ]}/>
      </Form.Item>

      <Form.List
        name='details'
        dependencies={['is_qc_passed']}
        rules={[({ getFieldValue }) => ({
          validator(__, value) {
            if (getFieldValue('is_qc_passed') || !value) { 
              return Promise.resolve() 
            }
            const total = value.reduce((prev, v) => prev + Number(_.get(v, 'percent', 0)) || 0, Number(0))
            if (total == 100) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(`${t('error_messages.validation.equal')} 100%`))
          }
        })]}
      >
        {(fields, {}, { errors }) => (
          <div>
            {fields.map(field => (
              <Form.Item
                labelAlign='right'
                name={[field.name, 'percent']}
                label={_.get(details, `${field.name}.setting_name`, '')}
              >
                <InputNumber style={{ width: '100%' }} disabled={qcPass != false} max={100} min={0} addonAfter='%' />
              </Form.Item>
            ))}
            <Form.Item
              labelAlign='right'
              label={t('datas.sum')}>
              <InputNumber style={{ width: '100%' }} readOnly variant='borderless' value={sumDefect} addonAfter='%'/>
              <Form.ErrorList errors={errors}/>
            </Form.Item>
          </div>
        )}
      </Form.List>
    </div>
  )
}

const defaultProps = {
  form: null,
}

DetailForm.propTypes = {
  form: propTypes.any,
}