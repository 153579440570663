import moment from "moment"


export const defaultBarcodeDetail = {
  id: null,
  barcode_no: '',
  wood_size_amount_map_list: []
}

export const defaultWoodSizeDetail = {
  id: null,
  grade: '',
  wood_code: '',
  width: '',
  length: 0,
  thick: 0,
  mil: 0,
  is_display: false,
}

export const defaultWoodSizeAmountMapDetail = {
  id: null,
  wood_code: '',
  amount: 0,
}

export const defaultWoodExportDetail = {
  barcode_no: '',
  wood_size_amount_map_list: [defaultWoodSizeAmountMapDetail]
}

export const defaultFactoryWoodExportTransactionDetail = {
  id: null,
  destination_factory: '',
  destination_factory_display: '',
  truck_company_name: '',
  license_plate: '',
  truck_transactions: [],
  wood_export_list: [defaultWoodExportDetail],
  created: ''
}

export const defaultWoodSizeGradeMapDetail = {
  id: null,
  wood_code: '',
  saw_wood_type: null,
  ab: 0,
  c: 0,
  p: 0,
  pp: 0,
  nac: 0,
  abc: 0,
  waste: 0,
  over: 0,
  buy_price: 0,
  sell_price: 0,
  barcode_ref: '',
  produce_date: moment().format(),
}

export const defaultWoodImportTransactionDetail = {
  id: null,
  order_no: '',
  produce_date: moment().format(),
  truck_transaction_list: [],
  wood_size_grade_map_list: [{...defaultWoodSizeGradeMapDetail}],
  workflow: null,
}

export const defaultWoodImportTruckTransactionDetail = {
  id: null,
  truck_company: null,
  truck_type: null,
  truck_type_name: "",
  license_plate: null,
  exported_net_weight: null,
  in_weight: null,
}

export const defaultWoodSizeBuyPriceMapDetail = {
  id: null,
  wood_code: '',
  truck_company: 0,
  vat: false,
  buy_ab: 0,
  buy_c: 0,
  buy_p: 0,
  buy_pp: 0,
  buy_abc: 0,
  buy_nac: 0,
  sell_ab: 0,
  sell_c: 0,
  sell_p: 0,
  sell_pp: 0,
  sell_abc: 0,
  sell_nac: 0,
}

export const WOOD_GRADES = {
  AB: 'AB',
  C: 'C',
  P: 'P',
  PP: 'PP',
  NAC: 'NAC',
  ABC: 'ABC',
}

export const WOOD_IMPORT_GRADE_DISPLAY = [
  'ab',
  'c',
  'p',
  'pp',
]

export const FACTORY_CHOICES = [
  { key: 'WU', value: 'WU', text: '(WU) บริษัท วู้ดเวอร์ค จำกัด สาขา 00000'},
  { key: 'WN', value: 'WN', text: '(WN) บริษัท วู้ดเวอร์ค จำกัด สาขา 00003'},
  { key: 'WC', value: 'WC', text: '(WC) บริษัท วู้ดเวอร์ค จำกัด สาขา 00001'},
  { key: 'WB', value: 'WB', text: '(WB) บริษัท วู้ดเวอร์ค จำกัด สาขา 00007'},
  { key: 'WA', value: 'WA', text: '(WA) บริษัท วู้ดเวอร์ค จำกัด สาขา 00002'},
  { key: 'WQ', value: 'WQ', text: '(WQ) บริษัท วู้ดเวอร์ค จำกัด สาขา 00008'},
  { key: 'WT', value: 'WT', text: '(WT) บริษัท วู้ดเวอร์ค จำกัด สาขา 00004'},
  { key: 'WP', value: 'WP', text: '(WP) บริษัท วู้ดเวอร์ค จำกัด สาขา 00005'},
  { key: 'WO', value: 'WO', text: '(WO) บริษัท วู้ดเวอร์ค จำกัด สาขา 00006'},
  { key: 'WG', value: 'WG', text: '(WG) บริษัท วู้ดเวอร์ค จำกัด สาขา (สำนักงานใหญ่)'},
  { key: 'WG2', value:  'WG2', text: '(WG2) Test Server (WG2)'},
]

export const WOOD_GRADE_CHOICES = [
  { key: 'AB', text: 'AB', value: 'AB' },
  { key: 'C', text: 'C', value: 'C' },
  { key: 'P', text: 'P', value: 'P' },
  { key: 'PP', text: 'PP', value: 'PP' },
]

export const defaultWetWoodDataDetail = {
  id: '',
  barcode: '',
  color: null,
  employee: '',
  employee_detail: '',
  oven: '',
  overall_volume: 0,
  time_buy_wood: null,
  time_close_oven: null,
  time_enter_oven: null,
  time_leave_oven: null,
  time_post_baking_group: null,
  truck_company: null,
  truck_company_detail: '',
  wage: 0,
  wet_wood_type: null,
  wet_wood_type_name: '',  
  wood_size_amount_map_list: []
}

export const WOOD_COLORS = {
  A1: 'A1',
  A2: 'A2',
  A3: 'A3',
  B1: 'B1',
}

export const defaultDryWoodDataDetail = {
  id: '',
  barcode: '',
  employee: '',
  employee_detail: '',
  overall_volume: 0,
  wage: 0,
  dry_wood_type: null,
  dry_wood_type_name: '',  
  wood_size_amount_map_list: []
}