import { Button, Form, InputNumber } from "antd";
import propTypes from "prop-types";
import React from "react";
import { GET } from "../../utils/HttpClient";
import ErrorMessage from "../common/antd/ErrorMessage";
import CustomDatePicker from "../common/antd/CustomDatePicker";
import { useTranslation } from "react-i18next";
import { CheckOutlined } from "@ant-design/icons";
import { URL_POST_BAKING, URL_QC } from "../../constance/urls";
import * as _ from "lodash";


export default function InputWoodData (props) {
  const {
    onSubmit,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const checkWoodData = async ({ leave_oven_date, oven }) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_QC.POST_BAKING_PALLET_COUNT_SUMMARY, { date: leave_oven_date, ovens: oven })
      const data = _.get(response, 'data', {})
      const totalPallet = Object.values(data).reduce((prev, count) => 
        prev + Number(count) || Number(0), Number(0)
      )
      if (!totalPallet) {
        setErrorMessages(t('post_baking_waste_wood_count.errors.no_wood'))
      } else {
        onSubmit({ leave_oven_date, oven })
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div style={{ marginTop: '3rem' }}>
      <ErrorMessage message={errorMessages}/>
      <Form form={form} onFinish={checkWoodData}>
        <Form.Item name='leave_oven_date' label={t('wood_datas.leave_oven_date')} rules={[{ required: true }]}>
          <CustomDatePicker placeholder={t('wood_datas.leave_oven_date')} />
        </Form.Item>
        <Form.Item name='oven' label={t('post_baking_waste_wood_count.oven')} rules={[{ required: true }]}>
          <InputNumber style={{ width: '100%' }} placeholder={t('post_baking_waste_wood_count.oven_no')}/>
        </Form.Item>
        <Button type='primary' loading={loading} icon={<CheckOutlined/>} size='large' htmlType='submit'>{t('confirm')}</Button>
      </Form>
    </div>
  )
}

const defaultProps = {
  onSubmit: () => null,
}

InputWoodData.propTypes = {
  onSubmit: propTypes.func,
}