import propTypes from "prop-types";
import React from "react";
import { POST } from "../../utils/HttpClient";
import { URL_QC } from "../../constance/urls";
import { Button, Flex, Form, message, Spin, Typography } from "antd";
import ErrorMessage from "../common/antd/ErrorMessage";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { SaveOutlined } from "@ant-design/icons";
import DetailForm from "./DetailForm";
import WetWoodDataDetail from "./WetWoodDataDetail";


export default function InputDetail (props) {
  const {
    wetWoodData,
    onBack,
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);

    try {
      await POST(URL_QC.QC_WET_WOOD_TRANSACTION, values)
      message.success({ content: t('success') })
      onBack()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (wetWoodData) {
      form.setFieldValue('wet_wood_data', wetWoodData)
    }
  }, [wetWoodData])

  return (
    <div style={{ marginTop: '3rem' }}>
      <WetWoodDataDetail id={wetWoodData} />
      <Typography.Title level={2}>{t('qc_sort')}</Typography.Title>
      <ErrorMessage message={errorMessages}/>
      <Spin spinning={loading}>
        <Form form={form} onFinish={handleSubmit} labelCol={{ span: 4 }}>
          <DetailForm form={form}/>
          <Flex justify='center' gap='middle'>
            <Button type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
            <Button onClick={onBack} loading={loading}>{t('back')}</Button>
          </Flex>
        </Form>
      </Spin>
    </div>
  )
}

const defaultProps = {
  wetWoodData: null,
  onBack: () => null,
}

InputDetail.propTypes = {
  wetWoodData: propTypes.string,
  onBack: propTypes.func,
}