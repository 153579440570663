import { Form, Layout, message, Modal, Popconfirm, Select, Space, theme, Typography } from "antd"
import React from "react"
import MainHeader from "../../components/common/MainHeader"
import { useTranslation } from "react-i18next"
import ColorButton from "../../components/common/ColorButton";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import useAntDropdownItem from "../../hooks/useAntDropdownItem";
import { URL_BARCODE, URL_QC, URL_REPORT } from "../../constance/urls";
import TSTable from "../../components/common/TSTable";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import { HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import { DELETE } from "../../utils/HttpClient";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";


export default function QCLogWoodReportListView () {

  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { token: { colorInfo, colorError } } = theme.useToken();
  const tableRef = React.useRef();
  const [params, setParams] = React.useState({});

  const sawTimeDropdown = useAntDropdownItem({ url: URL_BARCODE.SAW_TIME, label: 'time_name' })

  const columns = React.useMemo(() => [
    {
      title: t('log_wood_qcs.id'),
      dataIndex: 'qc_id',
      searcher: true
    },
    {
      title: t('log_wood_qcs.checks.date'),
      dataIndex: 'created',
      render: (value) => dayjs(value).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: t('log_wood_qcs.employee'),
      dataIndex: 'employee_code',
      searcher: true
    },
    {
      title: t('log_wood_qcs.employee'),
      dataIndex: 'employee_name',
      searcher: true
    },
    {
      title: t('log_wood_qcs.guage_number'),
      dataIndex: 'guage_number',
      searcher: true
    },
    {
      title: t('log_wood_qcs.log_wood_eval_size'),
      render: (__, original) => (
        <span>{_.get(original, 'log_wood_eval_size')} [{_.get(original, 'sum_amount')}]</span>
      )
    },
    {
      title: t('log_wood_qcs.checks.log_wood_eval_size'),
      render: (__, original) => (
        <span>
          { _.get(original, 'amount_no_1', 0) > 0 && <div>{t('log_wood_eval_sizes.no_1')} [{_.get(original, 'amount_no_1', 0)}]</div>}
          { _.get(original, 'amount_no_2', 0) > 0 && <div>{t('log_wood_eval_sizes.no_2')} [{_.get(original, 'amount_no_2', 0)}]</div>}
          { _.get(original, 'amount_no_3', 0) > 0 && <div>{t('log_wood_eval_sizes.no_3')} [{_.get(original, 'amount_no_3', 0)}]</div>}
          { _.get(original, 'amount_no_4', 0) > 0 && <div>{t('log_wood_eval_sizes.no_4')} [{_.get(original, 'amount_no_4', 0)}]</div>}
          { _.get(original, 'amount_no_5_1a', 0) > 0 && <div>{t('log_wood_eval_sizes.no_5_1a')} [{_.get(original, 'amount_no_5_1a', 0)}]</div>}
          { _.get(original, 'amount_no_5_1b', 0) > 0 && <div>{t('log_wood_eval_sizes.no_5_1b')} [{_.get(original, 'amount_no_5_1b', 0)}]</div>}
          { _.get(original, 'amount_no_5_2', 0) > 0 && <div>{t('log_wood_eval_sizes.no_5_2')} [{_.get(original, 'amount_no_5_2', 0)}]</div>}
        </span>
      ),
    },
    {
      title: t('log_wood_qcs.qc_waste_woods'),
      dataIndex: 'name',
      render: (__, original) => (
        <span>
          { _.get(original, 'amount_cracked', 0) > 0 && <div>{t('qc_waste_woods.cracked')} [{_.get(original, 'amount_cracked', 0)}]</div>}
          { _.get(original, 'amount_cracked_log', 0) > 0 && <div>{t('qc_waste_woods.cracked_log')} [{_.get(original, 'amount_cracked_log', 0)}]</div>}
        </span>
      ),
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (value) => (
        <Space>
          <ColorButton
            override={colorInfo}
            color='primary'
            variant='outlined'
            icon={<EditOutlined/>}
            onClick={() => history.push(`${PATH.QC_LOG_WOOD_REPORT}${value}/`)}
          >
            {t('edit')}
          </ColorButton>
          <Popconfirm
            title={t('confirms.delete_title')}
            description={t('confirms.delete_content')}
            okButtonProps={{ icon: <DeleteOutlined/>, content: t('delete'), danger: true }}
            cancelText={t('cancel')}
            onConfirm={() => handleDelete(value)}
          >
            <ColorButton
              override={colorError}
              color='primary'
              variant='outlined'
              icon={<DeleteOutlined/>}
            >
              {t('delete')}
            </ColorButton>
          </Popconfirm>
        </Space>
      )
    },
  ], [])

  const onSearch = async ({ date, saw_time }) => {
    setParams({ saw_time, date_after: _.get(date, 0, null), date_before: _.get(date, 1, null) })
  }
  
  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_QC.QC_LOG_WOOD_QC_TRANSACTION}${id}/`)
      if (tableRef.current) {
        tableRef.current.fetch()
      }
      message.success(t('success'))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (params && tableRef.current) {
      tableRef.current.fetch()
    }
  }, [params])

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.log_wood')}</Typography.Title>
          <Form form={form} onFinish={onSearch} layout='inline' style={{ justifyContent: 'center' }}>
            <Form.Item name='date' label={t('date')}>
              <CustomDatePicker style={{ width: '100%' }} range/>
            </Form.Item>

            <Form.Item name='saw_time' label={t('log_wood_qcs.saw_time')}>
              <Select options={sawTimeDropdown.options} allowClear placeholder={`${t('select')} ${t('log_wood_qcs.saw_time')}`}/>
            </Form.Item>
            <ColorButton type='primary' override={colorInfo} htmlType='submit' icon={<SearchOutlined/>}>{t('search')}</ColorButton>
          </Form>
          <ExportReportButton
            justify='end'
            url={URL_REPORT.QC_LOG_WOOD_QC_TRANSACTION_REPORT}
            method='get'
            exportTypes={['excel']}
            typeAsParams
            params={params}/>
          <TSTable
            ref={tableRef}
            url={URL_QC.QC_LOG_WOOD_QC_TRANSACTION}
            columns={columns}
            style={{ marginTop: '1rem' }}
            params={params} />
        </Container>
      </Layout.Content>
    </Layout>
  )
}