import { Form, Layout, message, Popconfirm, Space, theme, Typography } from "antd"
import React from "react"
import MainHeader from "../../components/common/MainHeader"
import { useTranslation } from "react-i18next"
import ColorButton from "../../components/common/ColorButton";
import { DeleteOutlined, EditOutlined, SearchOutlined } from "@ant-design/icons";
import { URL_QC, URL_REPORT } from "../../constance/urls";
import TSTable from "../../components/common/TSTable";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import { HUMAN_DATE_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import ExportReportButton from "../../components/common/antd/ExportReportButton";
import { DELETE } from "../../utils/HttpClient";
import CustomDatePicker from "../../components/common/antd/CustomDatePicker";


export default function QCPostBakingWoodReportListView () {

  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { token: { colorInfo, colorError } } = theme.useToken();
  const tableRef = React.useRef();
  const [params, setParams] = React.useState({});

  const columns = React.useMemo(() => [
    {
      title: t('log_wood_qcs.checks.date'),
      dataIndex: 'created',
      render: (value) => dayjs(value).format(HUMAN_DATETIME_FORMAT)
    },
    {
      title: t('log_wood_qcs.checks.employee'),
      dataIndex: 'created_by_code',
      searcher: true,
    },
    {
      title: t('log_wood_qcs.checks.employee'),
      dataIndex: 'created_by_name',
      searcher: true,
    },
    {
      title: t('wood_datas.leave_oven_date'),
      dataIndex: 'leave_oven_date',
      render: (value) => dayjs(value).format(HUMAN_DATE_FORMAT),
      searcher: true,
      dateSearcher: true,
    },
    {
      title: t('post_baking_waste_wood_count.oven'),
      dataIndex: 'oven',
      searcher: true,
    },
    {
      title: t('wood_datas.total_pallet'),
      dataIndex: 'sum_amount',
    },
    {
      title: ' ',
      dataIndex: 'id',
      render: (value) => (
        <Space>
          <ColorButton
            override={colorInfo}
            color='primary'
            variant='outlined'
            icon={<EditOutlined/>}
            onClick={() => history.push(`${PATH.QC_POST_BAKING_WOOD_REPORT}${value}/`)}
          >
            {t('edit')}
          </ColorButton>
          <Popconfirm
            title={t('confirms.delete_title')}
            description={t('confirms.delete_content')}
            okButtonProps={{ icon: <DeleteOutlined/>, content: t('delete'), danger: true }}
            cancelText={t('cancel')}
            onConfirm={() => handleDelete(value)}
          >
            <ColorButton
              override={colorError}
              color='primary'
              variant='outlined'
              icon={<DeleteOutlined/>}
            >
              {t('delete')}
            </ColorButton>
          </Popconfirm>
        </Space>
      )
    },
  ], [])

  const onSearch = async ({ date, ...value }) => {
    setParams({ ...value, date_after: _.get(date, 0, null), date_before: _.get(date, 1, null) })
  }
  
  const handleDelete = async (id) => {
    try {
      await DELETE(`${URL_QC.QC_POST_BAKING_WOOD_TRANSACTION}${id}/`)
      if (tableRef.current) {
        tableRef.current.fetch()
      }
      message.success(t('success'))
    } catch (error) {
      message.error(error.errorMessages)
    }
  }

  React.useEffect(() => {
    if (params && tableRef.current) {
      tableRef.current.fetch()
    }
  }, [params])

  return (
    <Layout>
      <Layout.Header>
        <MainHeader/>
      </Layout.Header>
      <Layout.Content>
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.wet_wood')}</Typography.Title>
          <Form form={form} onFinish={onSearch} layout='inline' style={{ justifyContent: 'center' }}>
            <Form.Item name='date' label={t('date')}>
              <CustomDatePicker style={{ width: '100%' }} range/>
            </Form.Item>
            <ColorButton type='primary' override={colorInfo} htmlType='submit' icon={<SearchOutlined/>}>{t('search')}</ColorButton>
          </Form>
          <ExportReportButton
            style={{ marginTop: '1rem' }}
            justify='end'
            url={URL_REPORT.QC_POST_BAKING_WOOD_TRANSACTION_REPORT}
            method='get'
            exportTypes={['excel']}
            typeAsParams
            params={params}/>
          <TSTable
            ref={tableRef}
            url={URL_QC.QC_POST_BAKING_WOOD_TRANSACTION}
            columns={columns}
            style={{ marginTop: '1rem' }}
            params={params} />
        </Container>
      </Layout.Content>
    </Layout>
  )
}