import React from "react";
import { PATH } from "../../routes/Routes";
import { useHistory } from "react-router-dom";
import { Layout } from "antd";
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/common/antd/SubHeader";
import BarcodeInput from "../../components/barcode/BarcodeInput";
import { URL_BARCODE } from "../../constance/urls";
import InputDetail from "../../components/qc-dry-wood/InputDetail";


const STEP = {
  ENTER_BARCODE: 'enter-barcode',
  INPUT_DETAIL: 'input-detail',
}

export default function QCDryWoodView () {
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = React.useState(STEP.ENTER_BARCODE);
  const [dryWoodData, setDryWoodData] = React.useState(null);

  const onBarcodeEntered = (id) => {
    setDryWoodData(id);
    setStep(STEP.INPUT_DETAIL);
  }

  const onBackToBarcodeEnter = () => {
    setDryWoodData(null);
    setStep(STEP.ENTER_BARCODE);
  }

  const renderStep = () => {
    switch (step) {
      case STEP.ENTER_BARCODE: 
        return <BarcodeInput
          submitUrl={URL_BARCODE.DRY_WOOD_DATA}
          onNext={onBarcodeEntered}/>
      case STEP.INPUT_DETAIL:
        return <InputDetail dryWoodData={dryWoodData} onBack={onBackToBarcodeEnter}/>
      default: return null
    }
  }

  const onBack = () => {
    history.push(PATH.QC_MAIN);
  }

  return (
    <Layout>
      <SubHeader title={t('qc_menus.dry_wood')} onBack={onBack}/>
      <Layout.Content>
        <div style={{ padding: '1rem 1rem' }}>
          {renderStep()}
        </div>
      </Layout.Content>
    </Layout>
  )
}