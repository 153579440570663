import { Button, Col, Dropdown, Image, Layout, Modal, Radio, Row } from "antd";
import { LeftOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons"
import React from "react";
import logo from "../../../assets/logo.png";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useUserContext } from "../../../hooks/UserSettingProvider";
import useLanguage from "../../../hooks/useLanguage";
import { PRIMARY_COLOR } from "../../../constance/color";
import { PATH } from "../../../routes/Routes";
import propTypes from "prop-types";


export default function SubHeader(props=defaultProps) {
  const { title, onBack } = props;

  const { user, onLogout } = useUserContext();
  const { t } = useTranslation();
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const { language, setLanguage } = useLanguage();

  const userMenus = [
    {
      key: '1',
      label: _.get(user, 'display_name', '-'),
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: t('menus.log_out'),
      icon: <LogoutOutlined />,
      onClick: () => modal.confirm({
        title: t('menus.log_out'),
        icon: <LogoutOutlined />,
        content: t('confirms.log_out'),
        okText: t('confirm'),
        cancelText: t('cancel'),
        onOk: () => {
          onLogout();
          history.replace({
            pathname: PATH.LOGIN,
            search: new URLSearchParams({ redirect: PATH.QC_MAIN }).toString(),
          });
        }
      })
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: (
        <Radio.Group value={language} onChange={e => setLanguage(e.target.value)}>
          <Radio.Button value='th'>TH</Radio.Button>
          <Radio.Button value='en'>EN</Radio.Button>
        </Radio.Group>
      )
    },
  ]

  return (
    <Layout.Header style={{
      position: 'sticky',
      top: 0,
      zIndex: 1,
      width: '100%',
      boxShadow: '0px 1px 2px #e6e6e6',
      fontWeight: 'bold',
      background: 'white',
      padding: '0 1rem',
    }}>
      <Row>
        <Col flex={20} style={{ textAlign: 'left' }}>
          <Button color='primary' variant='text' style={{ color: PRIMARY_COLOR }} onClick={() => {
            if (onBack) {
              onBack()
            } else {
              history.goBack()
            }
          }}>
            <LeftOutlined/>
          </Button>
          <label style={{ fontWeight: "bold", fontSize: "18px", color: PRIMARY_COLOR }}>{title}</label>
        </Col>
        <Col flex={4}>
          <Dropdown menu={{ items: userMenus }} placement='bottomRight'>
            <Button shape='circle' size='large'>
              <UserOutlined/>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      {contextHolder}
    </Layout.Header>
  )
}


const defaultProps = {
  title: '',
  onBack: null,
}


SubHeader.propsType = {
  title: propTypes.string,
  onBack: propTypes.func,
}