import { Descriptions, message, Spin, Table, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import * as _ from "lodash";
import { GET } from "../../utils/HttpClient";
import { URL_BARCODE } from "../../constance/urls";
import { defaultWetWoodDataDetail } from "../../constance/dataTypes";
import propTypes from "prop-types";


export default function WetWoodDataDetail (props) {
  const {
    id
  } = { ...defaultProps, ...props };

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState(defaultWetWoodDataDetail);

  const fetchWoodData = async (id) => {
    setLoading(true);
    try {
      const response = await GET(`${URL_BARCODE.WET_WOOD_DATA}${id}/`)
      setData(response.data)
    } catch (error) {
      message.error({ content: error.errorMessages })
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchWoodData(id);
    }
  }, [id])

  return (
    <Spin spinning={loading}>
      <Descriptions
        title={<Typography.Title level={4}>{t('barcodes.barcode_id')} : {_.get(data, 'barcode', '-')}</Typography.Title>} 
        style={{ marginTop: '1rem' }}
        column={1} bordered
        items={[
          {
            key: 'woodType',
            label: t('wood_datas.wood_type'),
            children: _.get(data, 'wet_wood_type_name', '-') + (_.get(data, 'color', null) ? `(${_.get(data, 'color', '')})` : '')
          },
          {
            key: 'overallVolume',
            label: t('wood_datas.overall_volume'),
            children: `${_.get(data, 'overall_volume', 0)} ${t('units.cubic')}${t('units.ft')}`
          },
          {
            key: 'employee',
            label: t('wood_datas.employee'),
            children: _.get(data, 'employee_detail', 0)
          },
          {
            key: 'wage',
            label: t('wood_datas.wage'),
            children: `${_.get(data, 'wage', 0)} ${t('units.baht')}`
          },
        ]}/>
      <Table
        style={{ marginTop: '1rem' }}
        className='colorless'
        pagination={false}
        columns={[
          { title: t('wood_size'), dataIndex: 'wood_code' },
          { title: t('barcodes.amount'), dataIndex: 'amount', render: value => `${value} ${t('units.wood')}` },
          { title: t('wood_datas.volume'), dataIndex: 'volume', render: value => `${value} ${t('units.cubic')}${t('units.ft')}`},
        ]}
        dataSource={_.get(data, 'wood_size_amount_map_list', [])}/>
    </Spin>
  )
}

const defaultProps = {
  id: null,
}

WetWoodDataDetail.propTypes = {
  id: propTypes.string,
}