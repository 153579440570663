import { Button, Descriptions, Flex, Form, Layout, message, Spin, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { SaveOutlined } from "@ant-design/icons";
import { URL_QC } from "../../constance/urls";
import Container from "../../components/common/antd/Container";
import * as _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import SubHeader from "../../components/common/SubHeader";
import { defaultQCPostBakingWoodTransactionDetail } from "../../constance/dataTypes";
import { GET, PATCH } from "../../utils/HttpClient";
import ErrorMessage from "../../components/common/antd/ErrorMessage";
import { HUMAN_DATE_FORMAT, HUMAN_DATETIME_FORMAT } from "../../constance/strings";
import dayjs from "dayjs";
import DetailForm from "../../components/qc-post-baking-wood/DetailForm";


export default function QCPostBakingWoodReportDetailView () {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = React.useState(defaultQCPostBakingWoodTransactionDetail);

  const onBack = () => {
    history.push(PATH.QC_POST_BAKING_WOOD_REPORT)
  }

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await GET(`${URL_QC.QC_POST_BAKING_WOOD_TRANSACTION}${id}/`)
      setData(response.data)
      form.setFieldsValue(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await PATCH(`${URL_QC.QC_POST_BAKING_WOOD_TRANSACTION}${id}/`, values);
      messageApi.success({ content: t('success') })
    } catch (error) {
      setErrorMessages(error.errorMessages);
      messageApi.error({ content: error.errorMessages })
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <Layout>
      <Layout.Header>
        <SubHeader/>
      </Layout.Header>
      <Layout.Content>
        {contextHolder}
        <Container style={{ padding: "0 1rem", marginTop: '3rem' }}>
          <Typography.Title level={3}>{t('menus.report_menus.qcs.post_baking_wood')}</Typography.Title>
          <ErrorMessage message={errorMessages}/>
          <Spin spinning={loading}>
            <Descriptions style={{ marginTop: '1rem' }} column={1} bordered items={[              
              {
                key: 'created',
                label: t('log_wood_qcs.checks.date'),
                children: dayjs(_.get(data, 'created', '')).format(HUMAN_DATETIME_FORMAT)
              },
              {
                key: 'created_by_code',
                label: t('log_wood_qcs.checks.employee'),
                children: `[${_.get(data, 'created_by_code', '')}] - ${_.get(data, 'created_by_name', '')}`
              },
              {
                key: 'leave_oven_date',
                label: t('wood_datas.leave_oven_date'),
                children: dayjs(_.get(data, 'created', '')).format(HUMAN_DATE_FORMAT)
              },
              {
                kay: 'oven',
                label: t('post_baking_waste_wood_count.oven'),
                children: _.get(data, 'oven', '-')
              },
            ]}/>
          </Spin>

          <Form
            style={{ marginTop: '2rem', textAlign: 'left' }}
            form={form}
            onFinish={handleSubmit}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Form.Item name='leave_oven_date' hidden rules={[{ required: true }]}/>
            <Form.Item name='oven' hidden rules={[{ required: true }]}/>
            <DetailForm form={form}/>

            <Flex justify='center' gap='middle' style={{ marginTop: '1rem', marginBottom: '1rem' }}>
              <Button size='large' type='primary' icon={<SaveOutlined/>} loading={loading} htmlType='submit'>{t('save')}</Button>
              <Button size='large' onClick={() => onBack()} loading={loading}>{t('back')}</Button>
            </Flex>
          </Form>
        </Container>
      </Layout.Content>
    </Layout>
  )
}