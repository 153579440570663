import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Container, Dropdown, Icon, Image, Menu, Popup } from 'semantic-ui-react';
import logo from '../../assets/logo.png';
import { PATH } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';
import { Media } from '../../AppMedia';
import useLanguage from '../../hooks/useLanguage';
import { useUserContext } from '../../hooks/UserSettingProvider';
export const MENU_APPROVE_LOGWOOD = 'approve_log_wood';
export const MENU_WOOD_IMPORT = 'wood_import';
export const MENU_WOOD_IMPORT_SETTINGS_PRICE = 'wood_import_settings_price';
export const MENU_FACTORY_WOOD_EXPORT = 'factory_wood_export';
export const MENU_BOM = 'bom';
export const MENU_WOOD_EXPORT = 'wood_export';
export const MENU_REPORT = 'report';
export const MENU_INTERFACE_AX = 'interface_AX';
export const MENU_ADMIN = 'admin';


function MainHeader (props) {

  const {
    activeItem
  } = props;

  const history = useHistory();

  const { t } = useTranslation();

  const { user, onLogout } = useUserContext();
  const { language, setLanguage } = useLanguage();

  const handleLogout = () => {
    onLogout();
    history.replace(PATH.LOGIN)
  }

  const logoLayout = () => {
    return (
      <Menu.Item as='a' onClick={() => history.replace(PATH.HOME)} header>
        <Image src={logo} size='mini' style={{marginRight: '1.5em'}}/>
        WoodWork
      </Menu.Item>
    )
  }

  const accountMenu = () => {
    return (
      <Menu.Item position='right'>
        <Dropdown icon={null} direction='left' trigger={<Icon name='user circle' size='big'/>}>
          <Dropdown.Menu>
            <Dropdown.Header content={user.display_name} />
            <Dropdown.Divider/>
            <Dropdown.Item onClick={() => history.replace(PATH.USER_PROFILE)}>
              <Icon name='user'/>
              {t('menus.user_profile')}
            </Dropdown.Item>
            <Dropdown.Item onClick={handleLogout}>
              <Icon name='sign-out'/>
              {t('menus.log_out')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu.Item>
    )
  }

  const rightLayout = () => {
    return (
      <Menu.Menu position='right'>
        { user.groups && user.groups.length > 0 && user.groups.includes('admin') &&
          <Menu.Item
            name={t('menus.admin')}
            icon='cogs'
            active={activeItem === MENU_ADMIN}
            onClick={() => history.replace(PATH.ADMIN)}
          />
        }
        <Menu.Item>
          <Dropdown
            pointing 
            text={language ? language.toUpperCase() : ''}
            icon='world'
            options={[
              {text: 'TH', value: 'th', key: 'th'},
              {text: 'EN', value: 'en', key: 'en'},
            ]}
            onChange={(_, data) => setLanguage(data.value)}
            selectOnBlur={false}
          />
        </Menu.Item>
        {accountMenu()}
      </Menu.Menu>
    )
  }

  const desktopLayout = () => {
    return (
      <Menu fixed='top' inverted>
        <Container>
          {logoLayout()}
          <Dropdown item simple text={t('menus.wood_menu')}>
            <Dropdown.Menu>
              <Dropdown.Item
                text={t('menus.wood_menus.log_wood_po')}
                onClick={() => history.replace(PATH.LOG_WOOD_PO)}
              />
              <Dropdown.Item
                text={t('menus.wood_menus.wood_import')}
                onClick={() => history.replace(PATH.WOOD_IMPORT)}
              />
              <Dropdown.Item
                text={t('menus.wood_menus.wood_import_settings_price')}
                onClick={() => history.replace(PATH.WOOD_IMPORT_SETTINGS_PRICE)}
              />
              { user.groups && user.groups.length > 0 && user.groups.includes('officer') &&
                <Dropdown.Item
                  text={t('menus.wood_menus.factory_wood_export')}
                  onClick={() => history.replace(PATH.FACTORY_WOOD_EXPORT)}
                />
              }
              <Dropdown.Item
                text={t('menus.wood_menus.bom')}
                onClick={() => history.replace(PATH.BOM)}
              />
              { user.groups && user.groups.length > 0 && user.groups.includes('export') &&
                <Dropdown.Item
                  text={t('menus.wood_menus.export_transaction')}
                  onClick={() => history.replace(PATH.EXPORT_TRANSACTION)}
                />
              }
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item simple text='Interface Microsoft AX'>
            <Dropdown.Menu>
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.log_wood_po')}
                onClick={() => history.replace(PATH.INTERFACE_AX_LOG_WOOD_PO)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.processed_wood_po')}
                onClick={() => history.replace(PATH.INTERFACE_AX_PROCESSED_WOOD_PO)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.movement_issue')}
                onClick={() => history.replace(PATH.INTERFACE_AX_MOVEMENT_ISSUE)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.movement_receipt')}
                onClick={() => history.replace(PATH.INTERFACE_AX_MOVEMENT_RECEIPT)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.bom')}
                onClick={() => history.replace(PATH.INTERFACE_AX_BOM_TRANSACTION)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.export_transaction')}
                onClick={() => history.replace(PATH.INTERFACE_AX_EXPORT_TRANSACTION)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.post_baking_waste_wood_count')}
                onClick={() => history.replace(PATH.INTERFACE_AX_POST_BAKING_WASTE_WOOD_COUNT)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.waste_wood_order_grading')}
                onClick={() => history.replace(PATH.INTERFACE_AX_WASTE_WOOD_ORDER_GRADING)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.waste_wood_order')}
                onClick={() => history.replace(PATH.INTERFACE_AX_WASTE_WOOD_ORDER)} />
              <Dropdown.Item 
                text={t('menus.interface_ax_menus.bom_waste_wood')}
                onClick={() => history.replace(PATH.INTERFACE_AX_BOM_WASTE_WOOD)} />
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown item simple text={t('menus.report')}>
            <Dropdown.Menu>
              <Dropdown.Item>
                <i className='dropdown icon' />
                <span className='text'>{t('menus.report_menus.log_wood')}</span>
                <Dropdown.Menu>
                  <Dropdown.Item 
                    text={t('menus.report_menus.log_wood_menus.log_wood_length')}
                    onClick={() => history.replace(PATH.REPORT_LOG_WOOD_LENGTH)} />
                  <Dropdown.Item 
                    text={t('menus.report_menus.log_wood_menus.log_wood_length_by_customer')}
                    onClick={() => history.replace(PATH.REPORT_LOG_WOOD_LENGTH_COMPANY)} />
                  <Dropdown.Item 
                    text={t('menus.report_menus.log_wood_menus.log_wood_species')}
                    onClick={() => history.replace(PATH.REPORT_LOG_WOOD_SPECIES)} />
                  <Dropdown.Item 
                    text={t('menus.report_menus.log_wood_menus.log_wood_species_by_customer')}
                    onClick={() => history.replace(PATH.REPORT_LOG_WOOD_SPECIES_COMPANY)} />
                  <Dropdown.Item 
                    text={t('menus.report_menus.log_wood_menus.cut_weight')}
                    onClick={() => history.replace(PATH.REPORT_LOG_WOOD_CUT_WEIGHT)} />
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item>
                <i className='dropdown icon' />
                <span className='text'>{t('menus.report_menus.wood_import')}</span>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_detail')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_by_customer')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_COMPANY)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_by_customer_and_date')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_COMPANY_DATE)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_by_width')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_THICK)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_by_volume')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_VOLUME)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_by_wood_code')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_WOOD_CODE)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.wood_import_menus.wood_import_out_grade')}
                    onClick={() => history.replace(PATH.REPORT_WOOD_IMPORT_OUT_GRADE)}/>
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item>
                <i className='dropdown icon' />
                <span className='text'>{t('menus.report_menus.waste_wood')}</span>
                <Dropdown.Menu>
                  <Dropdown.Item
                    text={t('menus.report_menus.waste_woods.post_baking_waste_wood_count')}
                    onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_COUNT_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.waste_woods.post_baking_waste_wood_input')}
                    onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_INPUT_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.waste_woods.post_baking_waste_wood_summary')}
                    onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_SUMMARY_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.waste_woods.post_baking_waste_wood_input_summary')}
                    onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_INPUT_SUMMARY_REPORT)}/>
                </Dropdown.Menu>
              </Dropdown.Item>
              <Dropdown.Item>
                <i className='dropdown icon' />
                <span className='text'>{t('menus.report_menus.qc')}</span>
                <Dropdown.Menu>
                  <Dropdown.Header>{t('qc_menus.log_wood')}</Dropdown.Header>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.log_wood')}
                    onClick={() => history.replace(PATH.QC_LOG_WOOD_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.log_wood_summary')}
                    onClick={() => history.replace(PATH.QC_LOG_WOOD_SUMMARY_REPORT)}/>
                  <Dropdown.Header>{t('qc_menus.raw_wood')}</Dropdown.Header>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.raw_wood')}
                    onClick={() => history.replace(PATH.QC_RAW_WOOD_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.raw_wood_saw_employee')}
                    onClick={() => history.replace(PATH.QC_RAW_WOOD_SAW_EMPLOYEE_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.raw_wood_on_table_employee')}
                    onClick={() => history.replace(PATH.QC_RAW_WOOD_ON_TABLE_EMPLOYEE_REPORT)}/>
                  <Dropdown.Header>{t('qc_menus.wet_wood')}</Dropdown.Header>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.wet_wood')}
                    onClick={() => history.replace(PATH.QC_WET_WOOD_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.wet_wood_summary')}
                    onClick={() => history.replace(PATH.QC_WET_WOOD_SUMMARY_REPORT)}/>
                  <Dropdown.Header>{t('qc_menus.post_baking_wood')}</Dropdown.Header>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.post_baking_wood')}
                    onClick={() => history.replace(PATH.QC_POST_BAKING_WOOD_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.post_baking_wood_summary')}
                    onClick={() => history.replace(PATH.QC_POST_BAKING_WOOD_SUMMARY_REPORT)}/>
                  <Dropdown.Header>{t('qc_menus.dry_wood')}</Dropdown.Header>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.dry_wood')}
                    onClick={() => history.replace(PATH.QC_DRY_WOOD_REPORT)}/>
                  <Dropdown.Item
                    text={t('menus.report_menus.qcs.dry_wood_summary')}
                    onClick={() => history.replace(PATH.QC_DRY_WOOD_SUMMARY_REPORT)}/>
                </Dropdown.Menu>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {rightLayout()}
        </Container>
      </Menu>
    )
  }

  const mobileLayout = () => {
    return (
      <Menu fixed='top' inverted>
      <Container>
          <Dropdown item icon={'list url'}>
            <Dropdown.Menu>
              <Dropdown.Item 
                text={t('menus.wood_menus.log_wood_po')}
                onClick={() => history.replace(PATH.LOG_WOOD_PO)} />
              <Dropdown.Item 
                text={t('menus.wood_menus.wood_import')}
                onClick={() => history.replace(PATH.WOOD_IMPORT)} />
              <Dropdown.Item 
                text={t('menus.wood_menus.post_baking_waste_wood_count')}
                onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_COUNT)} />
              <Dropdown.Item 
                text={t('menus.wood_menus.post_baking_waste_wood_input')}
                onClick={() => history.replace(PATH.POST_BAKING_WASTE_WOOD_INPUT)} />
            </Dropdown.Menu>
          </Dropdown>
        
         {logoLayout()}
         {accountMenu('left')}
      </Container>
    </Menu>
    )
  }

  return (
    <>
      <Media at={'sm'}>
        {mobileLayout()}
      </Media>
      <Media greaterThan={'sm'}>
        {desktopLayout()}
      </Media>
    </>
  )
}

export default MainHeader;

MainHeader.propTypes = {
  activeItem: PropTypes.string,
}

MainHeader.defaultProps = {
  activeItem: MENU_APPROVE_LOGWOOD,
}